<template>
  <el-dialog
      v-model="isShow"
      title="故障现象与处理结果"
      width="30%"
      :before-close="handleClose"
  >
    <el-descriptions :column="1">
      <div v-for="(fault, index) in faultList">
        <el-descriptions-item :label="`现象${index+1}：`" width="100">
          {{ selectDictLabel(dict.type.work_order_fault_phenomenon_type, fault.faultPhenomenonType) }};
          {{ fault.description }}
          <div>
            <el-image v-for="url in fault.faultImages" style="width: 50px; height: 50px;margin: 20px"
                      :preview-src-list="[url]"
                      :src="url" fit="contain"/>
          </div>
        </el-descriptions-item>
        <div v-if="isShowProcessResult">
          <el-descriptions-item label="故障原因：" width="100">{{ fault.reason }}</el-descriptions-item>
          <el-descriptions-item label="处理结果：" width="100">{{ fault.processResult }}</el-descriptions-item>
          <el-descriptions-item label="处理照片：" width="100">
            <el-image v-for="url in fault.processImages" style="width: 50px; height: 50px;margin:20px"
                      :preview-src-list="[url]"
                      :src="url" fit="contain"/>
          </el-descriptions-item>
        </div>
      </div>

    </el-descriptions>

    <template #footer>
          <span class="dialog-footer">
            <el-button @click="handleClose">关闭</el-button>
          </span>
    </template>
  </el-dialog>
</template>

<script>
import DictTag from "@/components/DictTag/index.vue";
import {selectDictLabel} from "../../utils/ruoyi";

let baseURL = process.env.VUE_APP_BASE_API
export default {
  name: "FaultAndProcessResult",
  dicts: ['work_order_fault_phenomenon_type'],
  components: {DictTag},
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    faultList: {
      type: Array,
      required: true,
    },
    isShowProcessResult: {
      type: Boolean,
      required: true,
    }
  },

  computed: {
    isShow: {
      get() {
        return this.visible
      },

      set(val) {
        this.$emit('update:visible', val)
      },
    },
  },
  created() {
    this.handleImage()
  },
  methods: {
    selectDictLabel,
    handleImage() {
      this.faultList.forEach((fault, index) => {
        const faultImages = []
        const processImages = []
        fault.faultImageList.forEach(file => {
          faultImages.push(baseURL + file.storageBlob.newFilename)
        })
        fault.processImageList.forEach(file => {
          processImages.push(baseURL + file.storageBlob.newFilename)
        })
        this.faultList[index].faultImages = faultImages
        this.faultList[index].processImages = processImages
      })
    },

    handleClose() {
      this.isShow = false
    },
  },
}
</script>

<style scoped>

</style>
